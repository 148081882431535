.btn-fix-video {
  position: fixed;
  bottom: 24%;
  right: 20px;
  z-index: 11;
}
.btn-only-video {
  background: #645CFC;
  color: #fff;
  text-decoration: none;
  border-radius: 60px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: 48px;
  transition: min-width 0.5s;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Polaris-Modal-Dialog--sizeLarge:has(.demo-video-wrap){
  max-width: 850px !important;
}
.btn-only-video span {
  white-space: nowrap;
  padding-right: 15px;
  display: none;
}
.btn-only-video svg path {
 fill: #fff;
}
.btn-only-video:hover {
  min-width: 160px;
  width: 100%;
}
.btn-only-video:hover span{
  display: block;
}
