:root,
html.p-theme-light {
  --brand-clr: #645CFC;
  --p-color-bg-surface-secondary: #f1f1f1;
  --p-space-700: 1.75rem;
  --p-space-1800: 4.5rem;
  --p-font-size-250: 0.625rem;
  --p-color-nav-bg-surface-selected: rgba(243, 243, 243, 1);
  --p-font-size-400: 1rem;
}

#primary_btn.Polaris-Button {
  --pc-button-box-shadow: var(--p-shadow-button-primary-success);
  --pc-button-box-shadow_active: var(--p-shadow-button-primary-success-inset);
  --pc-button-bg: var(--p-color-bg-fill-success);
  --pc-button-bg_hover: var(--p-color-bg-fill-success-hover);
  --pc-button-bg_active: var(--p-color-bg-fill-success-active);
  --pc-button-bg_disabled: var(--p-color-bg-fill-brand-disabled);
  --pc-button-color: var(--p-color-text-brand-on-bg-fill);
  --pc-button-color_disabled: var(--p-color-text-brand-on-bg-fill-disabled);
  --pc-button-icon-fill: var(--p-color-text-brand-on-bg-fill);
  --pc-button-icon-fill_disabled: var(--p-color-text-brand-on-bg-fill-disabled);
  margin: 0px;
}

.Polaris-ProgressBar__Indicator {
  background-color: #29845A !important;
}

.Polaris-TopBar__LeftContent .Polaris-TopBar__Logo {
  max-width: 140px;
}

/* .Polaris-Frame.Polaris-Frame--hasNav.Polaris-Frame--hasTopBar {
  background-color: var(--p-color-bg-inverse);
}
.Polaris-Frame__Main{
  background-color: var(--p-color-bg);
} */
.clrchnage-pri-second {
  /* color: var(--p-color-text); */
  color: var(--p-color-text-secondary);
}

.Polaris-Navigation {
  border-top-left-radius: var(--p-border-radius-300);
}

#AppFrameNav {
  background-color: var(--p-color-bg-inverse);
}

#AppFrameTopBar:before,
#AppFrameTopBar:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: var(--p-color-bg-inverse);
  right: 0;
  bottom: -20px;
}

#AppFrameTopBar:after {
  background: #f1f1f1;
  border-radius: 0 18px 0 0;
}

#AppFrameNav .Polaris-Navigation__PrimaryNavigation:hover {
  background: transparent;
}

#fix_hght {
  height: 32px;
}

.Polaris-Scrollable {
  scrollbar-width: thin;
}

.blt_clr {
  color: #B9B9B9;
}

.card_bg {
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 12px;
  background-color: #F3F3F3;
  position: relative;
  overflow: hidden;
}

.noti_img_block {
  width: 100%;
  /* height: 162px; */
  height: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 8px; */
  /* background-color: #F3F3F3; */
}

.noti_thumb_block {
  width: 100%;
  height: 134px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noti_thumb_block .Polaris-SkeletonThumbnail {
  width: 270px;
}

.noti_type_img {
  max-width: 100%;
  max-height: 120px;
  margin: auto;
  padding: 0px 10px 0px 10px;
  filter: drop-shadow(0px 4px 3px rgb(0 0 0 / 12%));
}

.notif_add_block {
  min-height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notif_card_bg {
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 12px;
  background-color: #F3F3F3;
  border: 1px solid #DEDEDE;
  /* box-shadow: 0px 0px 32px 0px #0000000F; */
}

.inln_bg {
  background-color: #F7F7F7;
}

.pageAction_paDDing .Polaris-PageActions {
  padding: var(--p-space-600) 0 0;
}

/* toggle css */
.switch {
  display: block;
  position: relative;
  width: 36px;
  height: 20px;
  background-color: white;
  border-radius: 12px;
  cursor: pointer;
}

#cursorPoint {
  cursor: pointer;
}

.switch input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 34px;
  cursor: pointer;
}

.switch-label {
  position: relative;
  display: block;
  height: inherit;
  background: #CAD0DE;
  border-radius: inherit;
  transition: 0.15s ease-out;
}

.switch input:checked~.switch-handle {
  left: 18px;
}

.switch input:checked~.switch-label {
  background: #34C759;
}

.switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 10px;
  background-image: linear-gradient(to bottom, white 40%, #f0f0f0);
  transition: left 0.15s ease-out;
}

/*header notification icon list hight */
.Polaris-Popover__Content--fullHeight {
  max-height: 30vh;
}

/*header install pixel */
.top-bar-custom .Polaris-TopBar-Menu__Activator {
  /* animation: changeBackgroundColor 2s infinite linear; */
  /* background-color: transparent !important; */
  /* margin-right: 50px; */
}

/* Language Transalate Css */

#langtrans .Polaris-Text--root {
  color: rgba(227, 227, 227, 1);
}

.top-bar-custom .step-pixel {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #F16468;
  position: absolute;
  margin: 10px 10px 10px 15px;
  top: -15px;
  right: -15px;
}

.Polaris-TopBar__RightContent .top-bar-custom .Polaris-TopBar-Menu__ActivatorWrapper .Polaris-TopBar-Menu__Activator {
  width: 115px;
}

.top-bar-custom button {
  padding: 0px;
}

.top-bar-custom button .pixlelinstall {
  padding: 6px;
}
.top-bar-ntfBtn button {
  background-color: #29845A;
  width: 134px!important;
}
.top-bar-ntfBtn button:hover {
  background-color: #165638; 
}
.Polaris-TopBar__Search .top-bar-custom .Polaris-TopBar-Menu__Activator {
  background-color: transparent !important;
}

.top-bar-custom .Polaris-TopBar-Menu__Activator:empty {
  display: none;
}

#btnInstall .Polaris-Button {
  background-color: #E51C00;
  padding: 6px 12px;
}

.Polaris-Button--tertiary,
.Polaris-Button--variantTertiary {
  background: var(--pc-button-color-hover);
  background: var(--pc-button-bg_hover);
}

/* .Polaris-IndexTable__TableCell:not(.Polaris-IndexTable__TableCell--flush) {
  padding: var(--p-space-400);
} */

#cursorPointer {
  cursor: pointer;
}

.notif_unSenCnt {
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
  background-color: #EE2A1E;
  padding: 4px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}


#dFlex {
  display: flex;
}

.Polaris-TextField__Input {
  font-size: inherit;
}

/* .container-custom .Polaris-Page {
  max-width: 1168px;
  margin: 0 auto;
} */

.Polaris-Page {
  max-width: 1080px !important;
  /* padding: 0 !important; */
  max-width: 1128px !important;
}

.Polaris-TextField__Backdrop {
  border-radius: 8px !important;
  outline: none !important;
}

input[disabled] {
  cursor: not-allowed;
}

.edit-notiname-block:hover .edit-notiname-icon {
  display: block;
}

.edit-notiname-icon {
  display: none;
}

.Polaris-Icon:has(.Polaris-Icon__Img) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Polaris-Icon .Polaris-Icon__Img {
  width: 12px;
}

.Polaris-Header-Title__SubTitle p {
  font-size: 13px;
}

.bottom_biilingWarning {
  position: fixed;
  bottom: 0;
  max-width: 620px !important;
  left: -232px;
  right: 0;
  margin: auto;
}

html:has(.Polaris-Modal-Dialog__Modal .full-screen-open) {
  overflow: hidden;
}

html:has(.Polaris-Modal-Dialog__Modal .full-screen-open) {
  overflow: hidden;
}

.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge:has(.full-screen-open) {
  width: 100%;
  max-width: 100%;
}

.Polaris-Modal-Dialog__Container:has(.Polaris-Modal-Dialog__Modal .full-screen-open) {
  justify-content: flex-end;
}

.Polaris-Modal-Dialog__Modal:has(.full-screen-open) {
  border-radius: var(--p-border-radius-400) var(--p-border-radius-400) 0 0;
}

.Polaris-Sheet {
  width: 100%;
  position: absolute;
  top: 56px;
}

.Polaris-Sheet__Container {
  width: 80%;
}

html:has(.Polaris-Sheet .full-screen-sheet-open) {
  overflow: hidden;
}

.Polaris-Sheet__Container:has(.Polaris-Sheet .full-screen-sheet-open) {
  animation: slideInFromRight ease-in-out;
}

.Polaris-Modal-Dialog__Container:has(.Polaris-Modal-Dialog__Modal .full-screen-open) {
  /* transition: transform .3s ease-out; */
  /* transform: translate(0, -50px); */
  /* animation-name: example; */
  /* animation-duration: 0.3s; */
  animation: scaleUp 0.5s ease-in-out;
}

#troubleshoot-btm-text {
  position: relative;
  top: -8px;
}

#AppFrameNav .Polaris-Navigation__Section--fill .Polaris-Navigation__Badge .Polaris-Badge--toneNew {
  background-color: var(--p-color-bg-fill-success);
  color: #fff;
  border-radius: 4px;
}

#AppFrameNav .Polaris-Navigation__Section--fill .Polaris-Navigation__Badge .Polaris-Badge--toneNew .Polaris-Text--medium {
  font-size: 10px;
}

.tooltipBulletin {
  display: inline-grid;
  grid-template-columns: 8px auto;
  column-gap: 8px;
}

.automate_img {
  width: 48px;
  height: 48px;
  background-color: #F0F2F5;
  border-radius: 9.75px;
  display: flex;
  align-items: center;
}

#secnd_btn.Polaris-Button {
  background: #303030 !important;
}

/* back btn css */
.header-title-managereviews .Polaris-Page-Header__BreadcrumbWrapper {
  display: none;
}

.Polaris-TopBar .Polaris-TopBar__Container {
  grid-template-columns: 1fr minmax(auto, 53rem) 1fr;
}

.alert-pixel-block {
  display: flex;
  font-Size: 12px;
  font-Weight: var(--p-font-weight-medium);
  /* padding-Left: 3px;
  padding-Right: 3px; */
  padding: 5px;
  margin-Right: 5px;
  border: 1px solid #E51C00;
  border-Radius: 10px;
  background-Color: rgba(229, 28, 0, 0.1);
}

#helpTitlecolor {
  color: #696969 !important;
}

#shopifyEmbedTextColor {
  color: #5F5F5F !important;
}

.help_card_bg {
  background-color: #F7F7F7;
  border-radius: 9px;
  padding: 16px;
}

@media (min-width: 48em) {
  .Polaris-TopBar .Polaris-TopBar__Search {
    justify-content: flex-start;
  }
}


@keyframes scaleUp {
  0% {
    transform: scale(1) translateY(1000px);
    opacity: 0;
  }

  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    /* Start from the right side (off-screen) */
  }

  100% {
    transform: translateX(0);
    /* Move to its normal position */
  }
}

/* .modal.show .modal-dialog {
  transform: none;
} */

.blink_me {
  animation: blinker 3s linear infinite;
  color: red;
}

@keyframes blinker {

  0%,
  100% {
    color: red;
  }

  50% {
    color: white;
  }
}

#langtrans {
  display: flex;
  align-items: center;
  width: 80px;
}

#lang-arrow {
  display: flex;
  padding: 0;
}

.dd-flag-img {
  width: 16px;
  height: 16px;
  object-fit: cover;
  margin: 0 8px 0 0;
}
/* .Polaris-Navigation__Section {
  padding-bottom: 0;
} */
.Polaris-IndexTable__EmptySearchResultWrapper .Polaris-LegacyStack__Item:nth-child(3) .Polaris-Text--subdued p {
  display: none;
}
/* .full-container .Polaris-Page,
.container-notiedit .Polaris-Page {
  max-width: 1640px !important;
}
.full-container #ntf_wbdetail{
  display: revert;
}
.wdgt_prvw_wrap>.Polaris-InlineGrid {
  grid-template-columns: 62.3% 25% !important;
  column-gap: 16px;
  justify-content: space-between;
}
.wDgt_height>.Polaris-InlineGrid {
  grid-template-columns: 36% 64% !important;
} */

@media (max-width: 1048px) {
  .Polaris-Frame--hasNav .Polaris-TopBar__Container {
    /* grid-template-columns: 1fr minmax(auto, 0rem) 1fr; */
  }
}

@media (max-width:767px) {
  .bottom_biilingWarning {
    left: 0;
  }

  .Polaris-Sheet__Container {
    width: 100%;
  }

  .alert-pixel-block .Polaris-InlineStack {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .alert-pixel-block .Polaris-InlineStack img {
    display: none;
  }
  .alert-pixel-block p.Polaris-Text--root {
      display: none;
  }
  .Polaris-TopBar__RightContent .top-bar-custom .Polaris-TopBar-Menu__ActivatorWrapper .Polaris-TopBar-Menu__Activator{
    width: 38px !important;
  }
  .pixlelinstall  .Polaris-Text--root{
    display: none;
  }
  #langtrans {
      width: 50px;
  }
  #lang-arrow .Polaris-Text--root{
    display: none;
  }
  #searchFilter {
    display: none;
  }
  .stepNo {
    min-width: 24px;
    min-height: 24px;
  }
  span:has(.text-settime){
    width: 100%;
    padding: 12px 0 0 42px;
    display: flex;
    align-items: center;
    align-self: center;
  }
  .settime-selectdd select,  .settime-selectdd .Polaris-Select__Content{
    font-size: 12px;
  }
  .settime-selectdd select option{
    font-size: 12px;
  }
  .text-settime{
    align-self: center;
  }


}

@media (max-width: 500px) and (max-height: 1024px) {
  .Polaris-Page {
    padding: var(--p-space-500);
  }

  .login-part-left {
    display: none;
  }
}

@media (max-width: 500px) {

  .login-part-left {
    display: none;
  }

  .lg_bg {
    height: auto;
  }

  .lg_content_ft {
    margin-top: 32px;
  }

}

@media (max-width: 374px) {

  #cloudflare-turnstile>div {
    width: 220px;
  }

}

@media (max-width: 375px) {
  .pixlelinstall {
    width: 90px;
  }
}




@keyframes changeBackgroundColor {
  0% {
    background-color: var(--p-color-bg-fill-inverse);
  }

  50% {
    background-color: transparent;
  }

  100% {
    background-color: var(--p-color-bg-fill-inverse);
  }
}